const startYear = 2000;
const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => `${startYear + index}`);


const listOptionManufactureYear: { id: string; label: string }[] = years.map(y => {
  return {
    id: y, 
    label: y
  }
});

export { listOptionManufactureYear }
