/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { Grid, Button } from "@mui/material";
import AutoCompleteInput from "../../../Components/FormComponents/AutoCompleteInput";
// import AutoCompleteInputWithConditionalTextField from "../../../Components/FormComponents/AutocompleteWithConditionalTextField";
import TextFieldInput from "Components/FormComponents/TextFieldInput";
import NumberInput from "Components/FormComponents/NumberInput";
import {
  getCarInfoCompanyByType,
  getCarInfoModelByType,
  getCarModelCodeByType,
} from "../../MainBody/api";
import { useTranslation } from "react-i18next";
import {
  SelectOption,
  CarTypeCompanyInfo,
  CarInfoModelType,
  CarModelCodeType,
} from "./type";
import { listOptionManufactureYear } from "./listOption";
import { checkIsModelCode } from "Common/common";
import _ from 'lodash';

interface specializedVehicleFormProps {
  onValueChange: (value: string) => void;
}

const SpecializedVehicle = ({ onValueChange }: specializedVehicleFormProps) => {
  const { t } = useTranslation();
  const [openCarModel, setOpenCarModel] = useState<boolean>(false);
  // const [openModelCode, setOpenModelCode] = useState<boolean>(false);

  const [selectedCarCompany, setSelectedCarCompany] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedModel, setSelectedModel] = useState<SelectOption>({
    id: "",
    label: "",
  });

  const [selectedModelCode, setSelectedModelCode] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedManufactured, setSelectedManufactured] =
    useState<SelectOption>({ id: "", label: "" });
  const [weight, setWeight] = useState<string>("");
  const [specializedVehicle, setSpecializedVehicle] = useState<string>("");

  const [otherValue, setOtherValue] = useState<string>("");

  const [listCarCompany, setListCarCompany] = useState<SelectOption[]>([]);
  const [listCarInfoModel, setListCarInfoModel] = useState<SelectOption[]>([]);
  const [listModelCode, setListModelCode] = useState<SelectOption[]>([]);

  const listHideCarBrand: string[] = useMemo(
    () => [
      "ed70991f-411c-4ac0-8ae4-0c7d66427e59",
      "06df3ccf-b96f-4c95-98fb-801b25295a66",
      "b7b77302-86a5-4d13-a621-d25bb9c48569",
      "f31e885d-dfc5-403c-beb2-da3d0a20c9ce",
      "20d8df48-232c-4711-9eac-b6fbf5d67253",
    ],
    []
  );

  const [modelCodeInput, setModelCodeInput] = useState<string>("");

  useEffect(() => {
    if (otherValue !== "") {
      if (checkIsModelCode(otherValue, listModelCode) !== -1) {
        setSelectedModelCode(
          listModelCode[checkIsModelCode(otherValue, listModelCode)]
        );
      }
    }
  }, [otherValue]);

  useEffect(() => {
    if (selectedModelCode && selectedModelCode.id === "other") {
      setSpecializedVehicle("");
      setSelectedManufactured({ id: "", label: "" });
      setWeight("");
    } else if (selectedModelCode && selectedModelCode.id !== "other") {
      setOtherValue("");
      setSpecializedVehicle(
        selectedModelCode.carTypeName ? selectedModelCode.carTypeName : ""
      );
      setWeight(
        selectedModelCode.payload ? selectedModelCode.payload.toString() : ""
      );
    }
  }, [selectedModelCode]);

  useEffect(() => {
    (async () => {
      const getCarInfo: CarTypeCompanyInfo[] = await getCarInfoCompanyByType(
        "specialized",
        1000
      );
      let newListOption: SelectOption[] = [];
      if (getCarInfo && getCarInfo.length > 0) {
        getCarInfo.forEach((company) => {
          const option: SelectOption = {
            id: company.companyKey,
            label: company.companyName,
          };

          newListOption.push(option);
        });
        listHideCarBrand.forEach((element) => {
          newListOption = newListOption.filter(
            (option) => option.id !== element
          );
        });

        setListCarCompany(newListOption);
      }
    })();
  }, [listHideCarBrand]);

  useEffect(() => {
    if (selectedCarCompany && selectedCarCompany.id !== "") {
      setSelectedModel({ id: "", label: "" });
      setListCarInfoModel([]);
      (async () => {
        const listCarInfoModel: CarInfoModelType[] =
          await getCarInfoModelByType(
            "specialized",
            selectedCarCompany?.id || "",
            1000
          );
        const newListOption: SelectOption[] = [];

        if (listCarInfoModel && listCarInfoModel.length > 0) {
          listCarInfoModel.forEach((model) => {
            const option: SelectOption = {
              id: `${model.versionKey}${model.modelCode}`,
              label: `${model.versionName} (${model.modelCode})`,
              versionKey: model.versionKey,
              modelCode: model.modelCode
            };
            newListOption.push(option);
          });

          setListCarInfoModel(newListOption);
        }
      })();
    } else {
      setSelectedModel({ id: "", label: "" });
    }
  }, [selectedCarCompany]);

  useEffect(() => {
    if (
      selectedModel &&
      selectedModel.id !== "" &&
      selectedCarCompany &&
      selectedCarCompany.id !== ""
    ) {
      setListModelCode([]);
      (async () => {
        const listModelCode: CarModelCodeType[] = await getCarModelCodeByType(
          "specialized",
          selectedCarCompany.id,
          selectedModel.versionKey,
          1000
        );
        // const newListModelCode: SelectOption[] = [];
        // if (listModelCode && listModelCode.length > 0) {
        //   listModelCode.forEach((item) => {
        //     const option: SelectOption = {
        //       id: item.modelCode,
        //       label: item.modelCode,
        //       carTypeName: item.carTypeName,
        //       payload: item.payload,
        //     };
        //     newListModelCode.push(option);
        //   });
        //   newListModelCode.push({ id: "other", label: "Khác" });
        //   setListModelCode(newListModelCode);
        // } else {
        //   setListModelCode([{ id: "other", label: "Khác" }]);
        // }

        if(!_.isEmpty(listModelCode)) {
          const currentModelCode: any = _.head(listModelCode);
          setSelectedModelCode({
              id: currentModelCode.modelCode,
              label: currentModelCode.modelCode,
              carTypeName: currentModelCode.carTypeName,
              payload: currentModelCode.payload,
          })
          setModelCodeInput(currentModelCode.modelCode);
        }
      })();
    }
  }, [selectedModel, selectedCarCompany]);

  useEffect(() => {
    if (!selectedCarCompany) {
      setSelectedCarCompany({ id: "", label: "" });
      setSelectedModel({ id: "", label: "" });
    } else {
      setSelectedModel({ id: "", label: "" });
    }
  }, [selectedCarCompany]);

  useEffect(() => {
    if (!selectedModel) {
      setSelectedModelCode({ id: "", label: "" });
      setOtherValue("");
      setWeight("");
      setSpecializedVehicle("");
      setSelectedManufactured({ id: "", label: "" });
    } else {
      setSelectedModelCode({ id: "", label: "" });
      setOtherValue("");
      setWeight("");
      setSpecializedVehicle("");
      setSelectedManufactured({ id: "", label: "" });
    }
  }, [selectedModel]);

  useEffect(() => {
    if (!selectedModelCode) {
      setSelectedModelCode({ id: "", label: "" });
      setWeight("");
      setSpecializedVehicle("");
      setSelectedManufactured({ id: "", label: "" });
      setOtherValue("");
    }
  }, [selectedModelCode]);

  useEffect(() => {
    if (selectedModelCode && selectedModelCode.id === "other") {
      setWeight("");
      setSpecializedVehicle("");
      setSelectedManufactured({ id: "", label: "" });
    } else if (selectedModelCode && selectedModelCode.id !== "other") {
      setOtherValue("");
    }
  }, [selectedModelCode]);

  //useEffect run when modelCodeInput has changed
  useEffect(() => {
    if (selectedModel && selectedModel.id && selectedModel.modelCode !== modelCodeInput) {
      setSelectedModelCode({ id: "other", label: "Khác" });
      setOtherValue(modelCodeInput);
    }
  }, [modelCodeInput]);

  useEffect(() => {
    onValueChange(
      JSON.stringify({
        company: selectedCarCompany,
        model: selectedModel,
        modelCode: selectedModelCode,
        otherValue,
        weight,
        specializedVehicle,
        selectedManufactured,
      })
    );
  }, [
    onValueChange,
    selectedCarCompany,
    selectedModel,
    selectedModelCode,
    weight,
    specializedVehicle,
    selectedManufactured,
    otherValue,
  ]);

  const isValidate =
    selectedCarCompany &&
    selectedCarCompany.id !== "" &&
    selectedModel &&
    selectedModel.id !== "" &&
    specializedVehicle !== "" &&
    selectedManufactured &&
    selectedManufactured.id !== "" &&
    ((selectedModelCode &&
      selectedModelCode.id !== "" &&
      selectedModelCode.id !== "other") ||
      (selectedModelCode &&
        selectedModelCode.id === "other" &&
        otherValue !== ""));

  return (
    <>
      <Grid item md={5.5} xs={12}>
        <AutoCompleteInput
          label={t("form.selection.brand.title")}
          listOption={listCarCompany}
          onChange={setSelectedCarCompany}
          value={selectedCarCompany}
          disabled={false}
          placeholder={t("form.selection.brand.placeholder")}
          onClose={() => {
            setOpenCarModel(true);
          }}
          onOpen={() => {
            setOpenCarModel(false);
          }}
        />
      </Grid>
      <Grid item md={5.5} xs={12}>
        <AutoCompleteInput
          label={"Hiệu xe - Phiên bản"}
          listOption={listCarInfoModel}
          onChange={setSelectedModel}
          value={selectedModel}
          disabled={
            selectedCarCompany && selectedCarCompany.id !== "" ? false : true
          }
          placeholder={t("form.selection.model.placeholder")}
          open={
            selectedCarCompany && selectedCarCompany.id !== "" && openCarModel
          }
          onClose={() => {
            setOpenCarModel(false);
          }}
          onOpen={() => {
            setOpenCarModel(!openCarModel);
          }}
        />
      </Grid>
      <Grid item md={5.5} xs={12}>
        {/* <AutoCompleteInputWithConditionalTextField
          label={"Số loại"}
          listOption={listModelCode}
          onChange={setSelectedModelCode}
          otherValue={otherValue}
          onChangeOtherValue={setOtherValue}
          value={selectedModelCode}
          disabled={selectedModel && selectedModel.id !== "" ? false : true}
          placeholder="Số loại"
          open={selectedModel && selectedModel.id !== "" && openModelCode}
          onClose={() => {
            setOpenModelCode(false);
          }}
          onOpen={() => {
            setOpenModelCode(!openModelCode);
          }}
        /> */}
         <TextFieldInput
            label={"Số loại"}
            onChange={setModelCodeInput}
            value={modelCodeInput}
            disabled={false}
            placeholder="Số loại"
          />
      </Grid>
      <Grid item md={5.5} xs={12}>
        <NumberInput
          name="weight"
          label={"Tải trọng"}
          onChange={setWeight}
          value={weight}
          disabled={
            selectedModelCode && selectedModelCode.id !== "" ? false : true
          }
          placeholder="Tải trọng"
        />
      </Grid>
      <Grid item md={5.5} xs={12}>
        <TextFieldInput
          label={"Loại xe chuyên dụng"}
          onChange={setSpecializedVehicle}
          value={specializedVehicle}
          disabled={
            selectedModelCode && selectedModelCode.id === "other" ? false : true
          }
          placeholder="Loại xe chuyên dụng"
        />
      </Grid>
      <Grid item md={5.5} xs={12}>
        <AutoCompleteInput
          label={t("form.selection.manufacture-year.title")}
          listOption={listOptionManufactureYear}
          onChange={setSelectedManufactured}
          value={selectedManufactured}
          placeholder="Năm sản xuất"
          disabled={
            selectedModelCode && selectedModelCode.id !== "" ? false : true
          }
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Button
          style={{
            width: "44%",
            height: "50px",
            background: `${isValidate ? "#5768FF" : "#f0f0f0"}`,
            borderRadius: "12px",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: `${isValidate ? "#ffffff" : "#bdbdbd"}`,
            textTransform: "unset",
            wordBreak: "keep-all",
            display: "block",
            margin: "0 auto",
          }}
          disabled={!isValidate}
          type="submit"
        >
          Tra cứu
        </Button>
      </Grid>
    </>
  );
};

export default SpecializedVehicle;
